import _readableStream from "readable-stream";
import _crc from "crc";
import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
const {
  Transform
} = _readableStream;
const {
  crc32
} = _crc;

class CRC32Stream extends Transform {
  constructor(options) {
    super(options);
    this.checksum = Buffer.allocUnsafe(4);
    this.checksum.writeInt32BE(0, 0);
    this.rawSize = 0;
  }

  _transform(chunk, encoding, callback) {
    if (chunk) {
      this.checksum = crc32(chunk, this.checksum);
      this.rawSize += chunk.length;
    }

    callback(null, chunk);
  }

  digest(encoding) {
    const checksum = Buffer.allocUnsafe(4);
    checksum.writeUInt32BE(this.checksum >>> 0, 0);
    return encoding ? checksum.toString(encoding) : checksum;
  }

  hex() {
    return this.digest("hex").toUpperCase();
  }

  size() {
    return this.rawSize;
  }

}

exports = CRC32Stream;
export default exports;